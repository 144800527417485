<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div style="position: sticky; top: 99px; z-index: 9; background: white">
        <div class="col-md-12" style="background: white">
          <div class="col-md-6" style="float: right">
            <router-link
              :to="{ name: 'client.whitelist.create' }"
              v-if="currentUser.u.roles == 'admin'"
            >
              <div class="btn btn-info" style="float: right">
                <i class="fa fa-plus"></i> Add
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>SN</th>
            <th>Company</th>
            <th>Contact Name</th>

            <th scope="col">Email</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody v-if="!loadingData && whitelistemail.length > 0">
          <tr v-for="(item, index) in whitelistemail" :key="index">
            <td>{{ ++index }}</td>
            <td>{{ item.company_name }}</td>
            <td>{{ item.contact_name }}</td>
            <td>{{ item.email }}</td>
            <td>
              <button class="btn btn-success btn-sm" @click="edit(item)">
                Edit
              </button>
              <button
                class="btn btn-danger btn-sm ml-2"
                @click="deleteItem(item)"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-if="!loadingData && whitelistemail.length < 1">
          <tr>
            <td colspan="8" class="text-center">No record(s) found</td>
          </tr>
        </tbody>
      </table>
      <!-- <button class="btn btn-success" @click="storeUpdate">Update</button> -->
    </div>
    <!-- <whitelistemail></whitelistemail> -->
    <!-- <whitelistemail
      v-if="ModalShow"
      v-on:closemodal="closeModal"
      :show-modal="ModalShow"
      :whitelistemail="whitelistemailData"
      :is_edit="is_edit"
    ></whitelistemail> -->
  </div>
</template>

<script>
// import moment from "moment";
import {
  GET_WHITELIST_EMAIL_LIST,
  DELETE_WHITELIST_EMAIL
} from "@/core/services/store/actions.type";
// import whitelistemail from "@/pages/backend/settings/whitelistemail/whitelistemail";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentUser"])
  },

  mounted() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(GET_WHITELIST_EMAIL_LIST).then(res => {
      this.whitelistemail = res.data;
    });
  },
  methods: {
    edit(item) {
      var client_slug = this.$route.params.client_slug;
      this.$router.push({
        name: "client.whitelist.edit",
        params: {
          client_slug: client_slug,
          id: item.id
        }
      });
    },

    deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover!",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes"
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store.dispatch(DELETE_WHITELIST_EMAIL, item.id).then(() => {
            this.$toastr.s("Email Successfully Deleted ");
            this.$store
              .dispatch(GET_WHITELIST_EMAIL_LIST, this.$route.params.id)
              .then(res => {
                this.whitelistemail = res.data;
              });
          });
        }
      });
    }
  },
  data() {
    return {
      loadingData: false,
      ModalShow: false,
      whitelistemail: [],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Setting",
          route: ""
        },
        {
          id: 3,
          title: "Email WhiteList",
          route: ""
        }
      ]
    };
  }
};
</script>
